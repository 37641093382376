import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)



const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: () => import('@/views/login/login.vue'),
    // component: () => import('@/views/login/anHua.vue'),
    meta: {
      title: '登录',
    }
  }
]

const router = new VueRouter({
  // mode: 'history',
  mode: 'hash',
  base: process.env.BASE_URL,
  // process.VUE_APP_BASE_API
  routes
})

export default router